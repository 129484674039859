import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false



import VueSocketIOExt from "vue-socket.io-extended";
import io from "socket.io-client";

let socket;

socket = io("127.0.0.1:4785", {
  reconnection: true, reconnectionDelay: 1000, reconnectionDelayMax: 5000, reconnectionAttempts: Infinity
});

socket.on('disconnect', () => {
  setTimeout(() => {
    console.log('Socket connect attempt')
    socket.connect()
  }, 5000)
})
Vue.use(VueSocketIOExt, socket);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
